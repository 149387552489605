<template>
    <b-modal
      v-model="data.showModal"
      size="lg"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <div class="flex gap-2">
            <span>Edit Sample -</span>
            <span v-if="sample">{{ sample.sample_number }}</span>
          </div>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div v-if="sample">
          <table class="table table-google">
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="row-fit">ID</td>
                <td>{{ sample.id }}</td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Sample Number</td>
                <td>
                  <b-form-input
                    v-model="sample.sample_number"
                    autocomplete="off"
                    autofocus
                    size="sm"
                    trim
                  ></b-form-input>
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Sample Date</td>
                <td>
                  <date-picker
                    class="date-picker"
                    v-model="sample.sample_date"
                    format="DD.MM.YYYY"
                    valueType="YYYY-MM-DD"
                    :clearable="false"
                    :lang="datepickerLocale"
                  />
                </td>
              </tr>

              <tr>
                <td class="row-fit text-nowrap">Scale</td>
                <td>{{ sample.device_description }} - {{ sample.device_model }}</td>
              </tr>
            </tbody>
          </table>

          <b-card no-body>
            <b-tabs pills card v-model="cycleIndex">
              <b-tab v-if="sample.cycles.length === 0" title="Cycles">
                <b-card-text>No cycles available</b-card-text>
              </b-tab>
              <b-tab
                v-for="(cycle,index) in sample.cycles"
                :key="cycle.id"
                :title="`Cycle ${cycle.cycle_number}`"
              >
                <b-card-text>
                  <cycle-graph-view
                    v-if="index === cycleIndex"
                    :cycle="cycle"
                    @updated="$emit('updated', $event)"
                  />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>

        <div v-else-if="isLoading">
          Loading..
        </div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="onDelete"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import {
  datepickerLocale,
  formatDuration,
} from '@/helpers';

const CycleGraphView = () => import('@/components/soot_samples/CycleGraphView.vue');

export default {
  name: 'SampleEditor',
  components: {
    CycleGraphView,
  },
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.sample) return false;
      if (this.isLoading) return false;
      if (this.sample.sample_number.length === 0) return false;
      return true;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
  },
  data() {
    return {
      loadingCount: 0,
      cycleIndex: 0,
      sample: null,
      graphData: null,
      filters: {},
      options: {},
    };
  },
  methods: {
    formatDuration(seconds) {
      return formatDuration(seconds);
    },
    fetchSample() {
      this.loadingCount++;
      this.$http
        .get(`/soot_sample/samples/${this.data.sampleId}`)
        .then((res) => {
          this.sample = res.body.sample;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    updateSample() {
      this.loadingCount++;
      this.$http
        .put(`/soot_sample/samples/${this.data.sampleId}`)
        .send({ changes: this.sample })
        .then((res) => {
          this.$emit('updated', res.body.sample);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchSample();
    },
    onSave() {
      this.updateSample();
    },
    onShow() {
      this.fetchAll();
    },
    onDelete() {
      if (!confirm('Do you really wish to delete this sample?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/soot_sample/samples/${this.data.sampleId}`)
        .then(() => {
          this.$emit('deleted', this.sample);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
